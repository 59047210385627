jQuery( document ).ready( function () {

	/**
	 * Only init smartbanner when library is loaded.
	 */
	if ( typeof jQuery.smartbanner !== "undefined" ) {

		// Detect Browser mode.
		detectIncognito().then( function ( result ) {
			// If browser mode is private then show smart banner.
			if ( true === result.isPrivate ) {
				loadSmartBanner();
			} else {
				// If not safari then load smart banner.
				if ( !( /^.*iPhone(?!.*CriOS).*Safari/.test( window.navigator.userAgent ) ) ) {
					loadSmartBanner();
				}
			}
		} ).catch( function ( error ) {
			console.log( error );
		} );

		/**
		 * Smart banner
		 */
		function loadSmartBanner() {
			/**
			 * SmartBanner init
			 */
			jQuery.smartbanner( {
				title: jQuery( 'meta[name="smartbanner:title"]' ).attr( 'content' ),
				author: jQuery( 'meta[name="smartbanner:author"]' ).attr( 'content' ),
				layer: true,
			} );
			var smartBanner = jQuery( 'div#smartbanner' );
			if ( 0 !== smartBanner.length ) {
				var smartBannerHeight = parseInt( smartBanner.height() );
				var htmlMarginTop     = parseInt(
					jQuery( 'html' ).css( 'margin-top' ).replace( 'px', '' )
				);
				jQuery( 'body' ).css( 'margin-top', smartBannerHeight - htmlMarginTop );
			}

			jQuery( document ).scroll(
				function () {
					if ( jQuery( '.site-header' ).hasClass( 'has-scrolled' ) ) {
						jQuery( 'body' ).css( 'margin-top', 'unset' );
					}
					if ( 0 !== smartBanner.length ) {
						var smartBannerVisible = inViewport(
							jQuery( 'div#smartbanner' ),
						);
						jQuery( 'body' ).css( 'margin-top', smartBannerVisible );
					}
				},
			);

			function inViewport( $el ) {
				var elH                                              = $el.outerHeight(),
				    H                                                = jQuery( window ).height(),
				    r = $el[0].getBoundingClientRect(), t = r.top, b = r.bottom;
				return Math.max(
					0,
					t > 0 ? Math.min( elH, H - t ) : Math.min( b, H )
				);
			}

		}
	}
} );
